import './App.css';
import React, {Component} from 'react';
import axios from 'axios';
import { Logger } from 'aws-amplify';
const logger = new Logger('App');
window.LOG_LEVEL = 'INFO';
class App extends Component {
    state = {
        selectedFile: null,
        fileUploadedSuccessfully: false,
        response:null
    }
    onFileChange = event => {
        this.setState({selectedFile: event.target.files[0]});
    }
    onFileUpload = () => {
        const formData = new FormData();
        formData.append(
            "demofile",
            this.state.selectedFile,
            this.state.selectedFile.name
        )
        logger.info(formData);
        axios.post("https://d9rvyyb7k2.execute-api.us-west-1.amazonaws.com/test/file-upload-to-s3", formData).then((res) => {
            logger.info(res.data)
            this.setState({response:res.data});
            this.setState({selectedFile: null});
            this.setState({fileUploadedSuccessfully: true});
        });
    }
    fileData = () => {
        if (this.state.selectedFile) {
            return(
            <div>
                <h2>Selected File</h2>
                <p>File Name : {this.state.selectedFile.name}</p>
            </div>
            )
        } else if (this.state.fileUploadedSuccessfully) {
            const myHTML = this.state.response;
            return (
                <div dangerouslySetInnerHTML={{ __html: myHTML }}/>
            )
        } else {
            return(
                <div>
                    <h4>choose a file and then press upload button</h4>
                </div>
            )
        }
    }
    render() {
        return(
            <div>
                <h2>Upload JFR file</h2>
                <div>
                    <input type="file" onChange={this.onFileChange} />
                    <button onClick={this.onFileUpload}>
                        Upload
                    </button>
                </div>
                {this.fileData()}
            </div>
        )
    }
}

export default App;
